<template>
  <div class="news-container">
    <div class="news-content">
      <div class="news-title">新闻列表</div>
      <div class="news-list-container" v-if="newsList.length > 0">
        <div
          v-for="(newItem, index) in newsList"
          :key="'new' + index"
          class="news-list-li"
          @click="toDetail(newItem.id)"
        >
          <div class="li-time">{{ newItem.updateTime }}</div>
          <div class="li-content">
            <img src="@/assets/img/home/news-icon.png" alt="" />

            <div class="li-title">
              <div>{{ newItem.title }}</div>
            </div>
            <div class="li-text">
              {{ newItem.showText }}
            </div>
          </div>
        </div>
      </div>
      <div class="noNews" v-else>暂无新闻，敬请期待~</div>
      <div v-if="newsList.length > 0" class="pagination-div">
        <Pagination
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="initList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";

import { getNewsList } from "@/api/news/news";
export default {
  name: "newsList",

  components: { Pagination },

  data() {
    return {
      newsList: [],
      total: 0, // 数据总数
      // 分页
      listQuery: {
        page: 1,
        limit: 10,
      },
    };
  },
  watch: {
    newsList(newVal) {
      newVal.forEach((element) => {
        element.showText = this.getHtmlPlainText(element.content);
        element.updateTime = element.updateTime.replace("T", "  ");
      });
    },
  },
  mounted() {
    this.initList();
  },
  methods: {
    initList() {
      let upData = {
        pageNum: this.listQuery.page,
        pageSize: this.listQuery.limit,
        type: 0,
        publish: 1,
      };
      getNewsList(upData).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.newsList = res.data.data.list;
          this.total = res.data.data.totalCount;
        }
      });
    },
    getHtmlPlainText(value) {
      value = value.replace(/\s*/g, ""); //去掉空格
      value = value.replace(/<[^>]+>/g, ""); //去掉所有的html标记
      value = value.replace(/↵/g, ""); //去掉所有的↵符号
      value = value.replace(/[\r\n]/g, ""); //去掉回车换行
      value = value.replace(/&nbsp;/g, ""); //去掉空格
      value = this.convertIdeogramToNormalCharacter(value);
      return value;
    },
    convertIdeogramToNormalCharacter(val) {
      const arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return val.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    toDetail(id) {
      this.$router.push({
        path: "/news/detail",
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .pagination-container {
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}
::v-deep .pagination-div .el-pagination {
  margin-top: 0px;
}
.news-container {
  background: #f4f4f4;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  //  新闻容器
  .news-content {
    // 新闻列表标题
    .noNews {
      margin-top: 260px;
      text-align: center;
    }
    .news-title {
      display: flex;
      justify-content: center;
      font-size: 24px;
      font-weight: 600;
      color: #1e2b4f;
      margin: 45px 0;
    }
    .news-list-container {
      // 新闻列表
      .news-list-li {
        width: 1000px;
        background: #ffffff;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        margin-bottom: 20px;

        // 新闻时间
        .li-time {
          position: absolute;
          top: 20px;
          right: 20px;
          font-size: 16px;
          color: #b1b6c3;
        }
        .li-content {
          padding: 30px 70px;
          position: relative;
          // 设置文本省略
          div {
            width: 800px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          img {
            position: absolute;
            top: 30px;
            left: 27px;
            width: 26px;
            height: 26px;
          }
          // 新闻标题
          .li-title {
            position: relative;

            div {
              font-size: 25px;
              color: #1e2b4f;
              margin-bottom: 10px;
            }
          }
          .li-text {
            font-size: 16px;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
